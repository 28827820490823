import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import Divider from '../sections/akkadian/Divider';
import imgBanner from "../assets/image/akkadian/happyteam.jpg";
import SignUpBlock from '../sections/akkadian/SignUpBlock';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Helmet } from "react-helmet";

const AboutPage = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "light",
          buttonText: "Contact us"
        }}
        footerConfig={{
          theme: "light",
          page: "akkadian-home",
          style: "style2", //style1, style2
        }}
      >
        <Helmet>
          <title>Akkadian Health - For Clinicians | Join Our Telehealth Team</title>
          <meta
            name="description"
            content="Explore opportunities for clinicians to join Akkadian Health’s telehealth team. Provide ADHD assessments and mental health services across Australia."
          />
        </Helmet>
        <div className="inner-banner">
          <Container>
            <Row className="justify-content-center mt-md-6 pt-24 pt-lg-29">
              <Col lg="9" xl="8">
                <div className="px-md-12 text-center mb-11 mb-lg-14">
                  <h1 className="title gr-text-3 mb-9 mb-lg-12">Interested in joining us?</h1>
                  <h3 className="gr-text-6 mb-6">
                    We want to help you - as a mental health professional - focus on what matters most; providing high-quality care to your clients. 
				  </h3>
				  <p className="gr-text-9 mb-6">
					At Akkadian Health you quickly become a valued member of our team, and we help you through dedicated technical, logistical, administrative and peer-to-peer support. Link up with our highly experienced senior clinicians for second opinions, advice, mentorship and support.
                  </p>
				  <p className="gr-text-9 mb-0">
					We're continuously onboarding psychiatrists, psychologists, mental health nurses and social workers to meet the growing demand for telehealth.
                  </p>				  
                </div>
              </Col>
			</Row>

			<Row className="justify-content-center">
        <Col xs={12} sm={4} md={4} lg={3} xl={2} className="mr-4 mb-4 d-flex justify-content-center">
					<AnchorLink to="#contact" className={`btn btn-primary gr-hover-y mr-4`}>
						Enquire now
					</AnchorLink>
        </Col>
        <Col xs={12} sm={4} md={4} lg={3} xl={2} className="mr-6 mb-4 d-flex justify-content-center">
					<Link to="/our-team#clinical" className={`btn text-primary btn-outline-primary gr-hover-y`}>
						Meet our team
					</Link>    
        </Col>      
			</Row>			
			
			<Row className="justify-content-center mt-md-3 pt-12 pt-lg-14">  		  
              <Col xs="2">
                <div className="banner-fluid-image pt-lg-6">
                </div>
              </Col>
              <Col xs="8">
                <div className="banner-fluid-image pt-lg-6">
                  <img src={imgBanner} alt="" className="d-flex text-center w-100 rounded-12" />
                </div>
              </Col>	
              <Col xs="2">
                <div className="banner-fluid-image pt-lg-6">
                </div>
              </Col>			  
            </Row>				

			
          </Container>
        </div>
        <div className="about-content pt-lg-28 pt-13 pb-13 pb-lg-25">
          <Container>
            <Row>
              <Col lg="6" className="mb-7 mb-lg-0">
                <div className="pr-xl-10">
                  <h2 className="title gr-text-4 mb-0">
                    Akkadian Health benefits
                  </h2>
                </div>
              </Col>
              <Col lg="6">
                <div className="pr-xl-13">
                  <h3 className="gr-text-8">Flexible</h3>
                  <p className="gr-text-10 mb-7 mb-lg-10">
                    Telehealth allows you to practice and provide mental health services from your home or office at times that suit you; from a few spare hours a week through to several days or even full-time equivalent loads.
                  </p>
                  <h3 className="gr-text-8">Focussed</h3>
                  <p className="gr-text-10 mb-7 mb-lg-10">
                    We want you to focus on what you’re good at – seeing clients. We take care of all the time-consuming logistics and overheads including managing referrals, administration and financial services, training and technical support.
                  </p>
                  <h3 className="gr-text-8">Optimised</h3>
                  <p className="gr-text-10 mb-7 mb-lg-10">
                    For Psychiatrists conducting assessments (MBS:291), we optimise your time with patients by conducting comprehensive pre-appointment intake screening, assessment and data gathering.
                  </p>
                  <h3 className="gr-text-8">Further development</h3>
                  <p className="gr-text-10 mb-7 mb-lg-10">
                    We want our health professionals to grow, and we offer regular peer-review and mentoring programs with regular opportunities to meet your Continuing Professional Development (CPD) and Continuing Medical Education (CME) requirements.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Divider />
        {/* <Testimonial page={"akkadian-about"} />
        <Team /> */}
        <SignUpBlock id={"contact"} buttonText={"Submit"} title={"Sound interesting?"} text={"If you are a registered psychiatrist or psychologist or a mental health clinician interested in joining our telehealth services, please contact us for a confidential non-binding discussion."} />
      </PageWrapper>
    </>
  );
};
export default AboutPage;
